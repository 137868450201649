// @ts-ignore Lodash merge doesn't export types
import merge from 'lodash.merge'

import { Campaign } from './api/Campaign'
import { Catalog } from './api/Catalog'
import { Giftcard } from './api/Giftcard'
import { Orders } from './api/Orders'
import { Partner } from './api/Partner'
import { Payment } from './api/Payment'
import { Profiles } from './api/Profiles'
import { Receipts } from './api/Receipts'
import { SingleSignOn } from './api/SingleSignOn'
import { DeviceOpportunities } from './bridge-api/deviceOpportunities'
import Me from './bridge-api/me'
import Registration from './bridge-api/registrations'
import Sessions from './bridge-api/sessions'
import Users from './bridge-api/users'
import type { MarketProperties } from './interfaces/marketProperties'
import {
  defaultProperties,
  propertiesPerMarkets,
} from './interfaces/marketProperties'
import { Request } from './request'

export default class Hummingbird {
  public readonly campaign: Campaign
  public readonly catalog: Catalog
  public readonly giftcard: Giftcard
  public readonly deviceOpportunities: DeviceOpportunities
  public readonly me: Me
  public readonly orders: Orders
  public readonly receipts: Receipts
  public readonly partner: Partner
  public readonly profiles: Profiles
  public readonly payment: Payment
  public readonly registration: Registration
  public readonly sessions: Sessions
  public readonly sso: SingleSignOn
  public readonly users: Users
  public country: string
  private readonly request: Request
  private userCountry: string | undefined
  private deviceId: string

  constructor(
    origin: string,
    nextoryEndpoint: string,
    localeIso: string,
    sandboxHeaders: Record<string, string>
  ) {
    const { country, language } = parseLocaleIso(localeIso)

    this.request = new Request(
      origin,
      nextoryEndpoint,
      country,
      language,
      sandboxHeaders
    )
    this.country = country

    this.deviceOpportunities = new DeviceOpportunities(this.request)
    this.me = new Me(this.request)
    this.registration = new Registration(this.request)
    this.sessions = new Sessions(this.request)
    this.users = new Users(this.request)

    // NX5-only APIs
    this.partner = new Partner(this.request)
    this.profiles = new Profiles(this.request)
    this.campaign = new Campaign(this.request)
    this.catalog = new Catalog(this.request)
    this.giftcard = new Giftcard(this.request)
    this.orders = new Orders(this.request)
    this.receipts = new Receipts(this.request)
    this.payment = new Payment(this.request)
    this.sso = new SingleSignOn(this.request)

    this.setUserMarket(country)
    this.setDeviceId(Hummingbird.createUUID())
  }

  /**
   * Set up by @nextory/auth
   *
   * @param $storage
   */
  setupDeviceId($storage: any) {
    const deviceId =
      $storage.getUniversal('deviceId') || Hummingbird.createUUID()

    if (import.meta.client) {
      // Only set cookie inside client, so server don't need to send a useless cookie (this allows Cloud CDN to cache the response)
      // If the user already have a deviceId, we'll use it instead
      $storage.setUniversal('deviceId', deviceId)
    }

    this.setDeviceId(deviceId)
  }

  /**
   * Helper function to create a unique device id.
   *
   * @see https://gist.github.com/jsmithdev/1f31f9f3912d40f6b60bdc7e8098ee9f
   */
  private static createUUID() {
    let dt = new Date().getTime()

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)

        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      }
    )
  }

  setDeviceId(deviceId: string) {
    this.deviceId = deviceId
    this.request._setDeviceId(deviceId)
  }

  setAuthToken(authToken?: string) {
    this.request._setAuthToken(authToken)
  }

  setLogLevel(logLevel: string | number) {
    this.request._setLogLevel(
      typeof logLevel === 'string' ? parseInt(logLevel) : logLevel
    )
  }

  /**
   * Shortcut to call `get` REST action on HTTP client
   *
   * @param {String} endpoint: Url of the request without baseURL => /api/selections
   * @param {Object} params: Parameters of the request
   */
  get(endpoint: string, params: object) {
    return this.request.http.get(endpoint, params)
  }

  /**
   * Shortcut to call `post` REST action on HTTP client
   *
   * @param {String} endpoint: Url of the request without baseURL => /api/selections
   * @param {Object} params: Parameters of the request
   */
  post(endpoint: string, params: object) {
    return this.request.http.post(endpoint, params)
  }

  /**
   * Shortcut to call `put` REST action on HTTP client
   *
   * @param {String} endpoint: Url of the request without baseURL => /api/selections/:id
   * @param {Object} params: Parameters of the request
   */
  put(endpoint: string, params: object) {
    return this.request.http.put(endpoint, params)
  }

  /**
   * Shortcut to call `patch` REST action on HTTP client
   *
   * @param {String} endpoint: Url of the request without baseURL => /api/selections/:id
   * @param {Object} params: Parameters of the request
   */
  patch(endpoint: string, params: object) {
    return this.request.http.patch(endpoint, params)
  }

  /**
   * Shortcut to call `delete` REST action on HTTP client
   *
   * @param {String} endpoint: Url of the request without baseURL => /api/selections/:id
   * @param {Object} params: Parameters of the request
   */
  delete(endpoint: string, params: object) {
    return this.request.http.delete(endpoint, params)
  }

  get marketProperties(): MarketProperties {
    return merge(
      defaultProperties(),
      propertiesPerMarkets()[this.userCountry || this.country]
    )
  }

  setUserMarket(country: string | undefined) {
    this.userCountry = country
    this.request._setCountry(country)
  }
}
