import validate from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import dev_45domain_45redirect_45global from "/builds/nextory/web/nx-web/middleware/dev-domain-redirect.global.ts";
import orange_45tunisia_45redirect_45global from "/builds/nextory/web/nx-web/middleware/orangeTunisiaRedirect.global.ts";
import polyglot_45market_45redirect_45global from "/builds/nextory/web/nx-web/middleware/polyglotMarketRedirect.global.ts";
import remove_45trailing_45slash_45global from "/builds/nextory/web/nx-web/middleware/remove-trailing-slash.global.ts";
import user_45country_45locale_45redirect_45global from "/builds/nextory/web/nx-web/middleware/user-country-locale-redirect.global.ts";
import manifest_45route_45rule from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  dev_45domain_45redirect_45global,
  orange_45tunisia_45redirect_45global,
  polyglot_45market_45redirect_45global,
  remove_45trailing_45slash_45global,
  user_45country_45locale_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "need-to-be-login": () => import("/builds/nextory/web/nx-web/middleware/need-to-be-login.ts"),
  "need-to-be-logout": () => import("/builds/nextory/web/nx-web/middleware/need-to-be-logout.ts")
}