<template>
  <NuxtLink
    :to="
      localePath({
        name: 'book-slug',
        params: {
          slug: createSlug(book.title, book.id),
        },
      })
    "
    class="book-card d-block mb-32 text-decoration-none"
    :class="{
      'book-card--dark': theme === 'dark',
    }"
  >
    <div class="book-card__cover-wrapper bg--white mb-8 position-relative br-5">
      <img
        :src="bookCoverUrl"
        class="book-card__cover-image br-5"
        data-test="book-cover"
        alt=""
        width="250"
        height="250"
        :fetchpriority="fetchPriority"
        :loading="lazyLoading"
      />
      <span
        v-if="book.is_adult_book && !$auth.loggedIn"
        class="book-card__cover-overlay text--body-small text--sand-200 position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center text-center p-12"
      >
        {{ $t('catalogue.book.cover_warning') }}
      </span>
    </div>

    <div class="book-card__notation mb-4">
      <StarRating :rating="bookRating" />
    </div>

    <div class="book-card__title">
      <h2
        class="headline--body mb-4 book-card__truncated-title"
        data-test="book-title"
      >
        {{ book.title }}
      </h2>
      <h3 class="text--meta mb-8 book-card__truncated-authors">
        {{ bookAuthors }}
      </h3>
      <div class="d-flex">
        <span
          v-for="(format, bookIndex) in book.formats"
          :key="bookIndex"
          class="icon-16 me-4"
          :class="[
            ['epub', 'pdf'].includes(format.type)
              ? 'icon--ebook'
              : 'icon--audiobook',
          ]"
        >
          {{ ['epub', 'pdf'].includes(format.type) ? 'book' : 'audiobook' }}
        </span>
      </div>
    </div>
  </NuxtLink>
</template>

<script>
import { getRecommendedCoverFromFormats } from '~/helpers/metaElements'

export default defineNuxtComponent({
  props: {
    book: {
      type: Object,
      required: true,
    },

    theme: {
      type: String,
      default: 'light',
      validator: value => ['light', 'dark'].includes(value),
    },

    index: {
      /**
       * The index of the book in the list let us put image loading priorities in a single place,
       * the first ones being the most important.
       * Objective here is to improve the Largest Contentful Paint of the page.
       *
       * This will affect the fecthpriority and lazy loading of the image.
       */
      type: Number,
      default: 0,
    },
  },

  computed: {
    fetchPriority() {
      if (this.index < 3) {
        return 'high'
      } else if (this.index < 6) {
        return 'medium'
      } else {
        return 'low'
      }
    },

    lazyLoading() {
      return this.index < 6 ? 'eager' : 'lazy'
    },

    bookCoverUrl() {
      let coverImg = getRecommendedCoverFromFormats(this.book.formats)?.img_url

      if (coverImg) {
        const coverUrl = new URL(coverImg)

        // Note: Setting both will constraint the size, without changing the aspect ratio
        coverUrl.searchParams.set('w', '250')
        coverUrl.searchParams.set('h', '250')

        if (this.book.is_adult_book && !this.$auth.loggedIn) {
          // Adult books have a special filter, so we can put a warning on top of it 🔞
          // For now, it's only for logged-out users.

          // - It's blurred
          coverUrl.searchParams.set('blur', '200')
          // - Aspect ratio is forced to 1:1
          coverUrl.searchParams.set('ar', '1')
          coverUrl.searchParams.set('fit', 'crop')
          // - Brightness and saturation are reduced
          coverUrl.searchParams.set('bri', '-60')
          coverUrl.searchParams.set('sat', '-45')
        }

        coverImg = coverUrl.toString()
      }

      return coverImg
    },

    bookAuthors() {
      return this.book.authors.map(author => author.name).join(', ')
    },

    bookRating() {
      return Math.round(this.book.average_rating) || 0
    },
  },

  mounted() {
    this.isLoading = false
  },

  methods: {
    createSlug,
  },
})
</script>

<style lang="scss">
@media (prefers-reduced-motion: reduce) {
  .book-card {
    &:hover {
      .book-card__cover-overlay {
        transform: none !important;
      }
    }
  }
}

.book-card {
  color: $sand-800;
  transition: color 250ms;

  // .book-card__cover-image
  &__cover-image {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  // .book-card__cover-wrapper
  &__cover-wrapper {
    box-shadow: rgba(0, 0, 0, 0.3) 0 0.8rem 0.8rem -0.8rem;
    transition: transform 250ms ease-out;

    .book-card__cover-image {
      transition: filter 250ms ease-out;
    }
  }

  &__cover-overlay {
    opacity: 1;
    transition: opacity 250ms ease-out;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    // When hovering over the card, the card scales up
    .book-card__cover-wrapper {
      transform: scale(1.05);

      // When hovering over the card, the cover image become slightly darker
      .book-card__cover-image {
        filter: brightness(70%);
      }
    }

    .book-card__cover-overlay {
      opacity: 0.7;
    }
  }

  &--dark {
    color: $white;

    &:hover {
      color: $gray-400;
    }
  }

  // .book-card__truncated-title
  &__truncated-title {
    display: -webkit-box;
    // line-height is 2rem, so 3 lines will be 6rem
    max-height: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  // .book-card__truncated-authors
  &__truncated-authors {
    display: -webkit-box;
    // line-height is 2rem, so 1 lines will be 2rem
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}
</style>
