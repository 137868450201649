import type { CountryCode } from 'libphonenumber-js/types'
import { useGtm } from '@gtm-support/vue-gtm'
import consola from 'consola'
import type { DataLayerObject } from '@gtm-support/core'

/*
 * 📊 $tracker is a plugin that allows us to track events in Google Tag Manager.
 *
 * 🤔 Since the Nextory flow is a bit complex, the naming and data may be a bit confusing.
 *
 * 📝 For example, to tell if a user subscribed to the newsletter, we send this kind of event:
 * - Subscribed: event "generate_lead" of 1 SEK
 * - Not subscribed: event "generate_lead" of 0 SEK
 *
 * 🚀 Unfortunately, this discrepancy is due to the fact that we want to fit Enhanced Ecommerce as best as possible.
 *
 * 📖 To find out more about the specifications from Middleout / Filip Kvist, please see:
 * https://docs.google.com/document/d/1JsEB_TTKc6YtXeg6ghap4ql-AZa_hIkJYY2RaCXFe1U/view
 */

const LAST_SEEN_BOOK_KEY = 'lastSeenBook'

const generateEventId = (): string =>
  `id_${Date.now()}_${Math.random().toString(16).substring(2)}`

interface BookItemInterface {
  item_brand: string
  item_id: string
  item_name: string
  item_variant: string
}

const rememberLastSeenBook = (item: BookItemInterface): void => {
  if (import.meta.client) {
    localStorage.setItem(LAST_SEEN_BOOK_KEY, JSON.stringify(item))
  }
}

const clearPreviousData = ($gtm: any): void => {
  $gtm.push({
    ecommerce: null,
  })
}

const createEcommerce = (params: {
  campaignCode?: string
  currency: string
  items: any[]
}): any => {
  return {
    value: 0,
    currency: params.currency,
    coupon: params.campaignCode || '(not set)',
    items: params.items,
  }
}

const createItems = (params: {
  subscriptionName?: string
  subscriptionPrice: number
  trialDays: number
}): any[] => {
  // Create subscription item
  const subscriptionItem = {
    item_id: `${params.trialDays} days`,
    item_variant: 'subscription',
  } as Record<string, string>

  if (params.subscriptionName) {
    subscriptionItem.item_name = params.subscriptionName
  }

  // We also remove null values (avoids having "null" in the array when no book was seen before 📘)
  return [lastSeenBook(), subscriptionItem].filter(Boolean)
}

const lastSeenBook = (): BookItemInterface | null => {
  try {
    if (import.meta.client) {
      const lastSeenBook = localStorage.getItem(LAST_SEEN_BOOK_KEY)

      if (lastSeenBook) {
        return JSON.parse(lastSeenBook)
      }
    }
  } catch {
    // Silently fail in case JSON can't be parsed (which shouldn't happen, but who knows 🤷)
  }

  return null
}

const hash = async (value: string | undefined) => {
  if (!value) {
    // As a fallback, return an empty string
    return ''
  }

  const { Sha256 } = await import('@aws-crypto/sha256-js')
  const hash = new Sha256()
  hash.update(value)

  const rawString = await hash.digest()

  return Array.from(rawString)
    .map(byte => {
      return ('0' + (byte & 0xff).toString(16)).slice(-2)
    })
    .join('')
}

export default defineNuxtPlugin({
  name: 'tracker',
  parallel: true,
  setup(nuxtApp) {
    const logger = consola.withTag('tracker')
    const $gtm = useGtm() || {
      // Mock $gtm object when not available
      push: (data: DataLayerObject): void => {
        logger.info('push', data)
      },
    }

    const { $hummingbird, $store } = useNuxtApp()

    nuxtApp.provide('tracker', {
      viewItem: ({
        author,
        format,
        id,
        name,
      }: {
        author: string
        format: string
        id: number
        name: string
      }): void => {
        const item: BookItemInterface = {
          item_brand: author,
          item_id: id.toString(),
          item_name: name,
          item_variant: format,
        }

        clearPreviousData($gtm)
        $gtm.push({
          event_id: generateEventId(),
          event: 'view_item', // 👀
          ecommerce: {
            value: 0,
            currency: 'SEK',
            items: [item],
          },
        })

        rememberLastSeenBook(item)
      },

      beforeRegistration: (params: { campaignCode?: string }): void => {
        // Triggered when user is on /register 📝
        clearPreviousData($gtm)
        const items = createItems({
          subscriptionPrice: 0,
          trialDays: $store?.state.webConfig.config.trialDays,
        })
        const ecommerce = createEcommerce({
          campaignCode: params.campaignCode,
          currency: $store?.state.webConfig.config.minimumPrice.currency,
          items,
        })
        $gtm.push({
          event_id: generateEventId(),
          event: 'add_to_cart', // 🛒
          ecommerce,
        })
      },

      registration: (params: { campaignCode?: string }): void => {
        // Triggered when user is on /profile/registration 📝
        clearPreviousData($gtm)
        const items = createItems({
          subscriptionPrice: 0,
          trialDays: $store?.state.webConfig.config.trialDays,
        })
        const ecommerce = createEcommerce({
          campaignCode: params.campaignCode,
          currency: $store?.state.webConfig.config.minimumPrice.currency,
          items,
        })
        $gtm.push({
          event_id: generateEventId(),
          event: 'view_cart', // 🛍️
          ecommerce,
        })
      },

      generateLead: async (params: {
        hasSubscribedToNewsletter: boolean
        userEmail: string
        userId: string
      }) => {
        // Triggered when user is on /register/planinfo 💡
        $gtm.push({
          event_id: generateEventId(),
          event: 'generate_lead', // 🔥
          value: params.hasSubscribedToNewsletter ? 1 : 0,
          currency: 'SEK',
        })
        clearPreviousData($gtm)
        $gtm.push({
          user_id: params.userId,
          external_id: params.userId,
          event: 'user_data', // 🧑‍🤝‍🧑
          user_data_hashed: {
            em: await hash(params.userEmail),
          },
        })
      },

      beginCheckout: (params: {
        campaignCode?: string
        userId: string
      }): void => {
        // Triggered when user is on /register/planform 🛍️
        clearPreviousData($gtm)
        const items = createItems({
          subscriptionPrice: 0,
          trialDays: $store?.state.webConfig.config.trialDays,
        })
        const ecommerce = createEcommerce({
          campaignCode: params.campaignCode,
          currency: $store?.state.webConfig.config.minimumPrice.currency,
          items,
        })
        $gtm.push({
          user_id: params.userId,
          external_id: params.userId,
          event_id: generateEventId(),
          event: 'begin_checkout', // 🛒
          ecommerce,
        })
      },

      choosePaymentMode: (params: {
        campaignCode?: string
        subscriptionName: string
        subscriptionPrice: number
        userId: string
      }): void => {
        // Triggered when user is on /register/payment-mode 💸
        clearPreviousData($gtm)
        const items = createItems({
          subscriptionName: params.subscriptionName,
          subscriptionPrice: params.subscriptionPrice,
          trialDays: $store?.state.webConfig.config.trialDays,
        })
        const ecommerce = createEcommerce({
          campaignCode: params.campaignCode,
          currency: $store?.state.webConfig.config.minimumPrice.currency,
          items,
        })
        $gtm.push({
          user_id: params.userId,
          external_id: params.userId,
          event_id: generateEventId(),
          event: 'add_shipping_info', // ⛵
          ecommerce,
        })
      },

      payment: (params: {
        campaignCode?: string
        paymentMode: string
        subscriptionName: string
        subscriptionPrice: number
        userId: string
      }): void => {
        // Triggered when user is on /register/card, /register/paypal, /register/trustly, ... 💳
        clearPreviousData($gtm)
        const items = createItems({
          subscriptionName: params.subscriptionName,
          subscriptionPrice: params.subscriptionPrice,
          trialDays: $store?.state.webConfig.config.trialDays,
        })
        const ecommerce = createEcommerce({
          campaignCode: params.campaignCode,
          currency: $store?.state.webConfig.config.minimumPrice.currency,
          items,
        })
        $gtm.push({
          user_id: params.userId,
          external_id: params.userId,
          event_id: generateEventId(),
          event: 'add_payment_info', // 💳
          ecommerce: {
            ...ecommerce,
            payment_type: params.paymentMode,
          },
        })
      },

      purchase: async (params: {
        campaignCode?: string

        orderId: string
        paymentMode: string
        subscriptionName: string
        subscriptionPrice: number
        userEmail: string

        userId: string
      }) => {
        // Triggered when user is on /register/payment-success 🎉
        // (just before redirecting to /register/welcome)
        clearPreviousData($gtm)
        const items = createItems({
          subscriptionName: params.subscriptionName,
          subscriptionPrice: params.subscriptionPrice,
          trialDays: $store?.state.webConfig.config.trialDays,
        })
        const ecommerce = createEcommerce({
          campaignCode: params.campaignCode,
          currency: $store?.state.webConfig.config.minimumPrice.currency,
          items,
        })
        $gtm.push({
          user_id: params.userId,
          external_id: params.userId,
          event_id: generateEventId(),
          event: 'purchase', // 💰
          ecommerce: {
            ...ecommerce,
            transaction_id: params.orderId,
            payment_type: params.paymentMode,
            hashed_email: await hash(params.userEmail),
          },
        })
      },

      setUserData: async (params: {
        email?: string
        firstName?: string
        lastName?: string
        phone?: string
        userId: string
      }): Promise<void> => {
        // Convert phone number to E.164 format for tracking purposes 📱
        let phoneNumber = ''
        if (params.phone) {
          try {
            const { parsePhoneNumber } = await import('libphonenumber-js')
            phoneNumber = parsePhoneNumber(
              params.phone,
              $hummingbird.country as CountryCode
            ).format('E.164')
          } catch {
            // Do nothing if phone number is invalid 🤷‍♂️
          }
        }

        // Triggered when user is on /register/profiles 🧑‍🤝‍🧑
        clearPreviousData($gtm)
        $gtm.push({
          user_id: params.userId,
          external_id: params.userId,
          event: 'user_data', // 🙋‍♂️
          user_data_hashed: {
            ph: await hash(phoneNumber),
            em: await hash(params.email),
            fn: await hash(params.firstName),
            ln: await hash(params.lastName),
          },
        })
      },

      // Used to track AB tests in ABTestVariant.vue
      experiment: (experimentName: string, variant: string): void => {
        $gtm.push({
          event: 'experiment',
          experiment: experimentName,
          variant,
        })
      },
    })
  },
})
